<template>
  <section :class="$customSectionClasses(props.cfgs)">
    <div class="opportunities-root">
      <img
        src="~/assets/img/svg/figures/opportunitesBlock/pattern.svg"
        alt="pattern"
        class="patternImg"
      >
      <img
        src="~/assets/img/svg/figures/opportunitesBlock/pattern.svg"
        alt="pattern"
        class="patternImg2"
      >
      <img
        src="~/assets/img/svg/figures/opportunitesBlock/whiteSquare.svg"
        alt="pattern"
        class="whiteSquare"
      >
      <div class="container">
        <div class="opportunities-items">
          <OpportunitiesItem
            v-for="(item, idx) in values.items"
            :key="`item-${idx}`"
            :item="item"
            :index="idx"
            :last-index="values.items.length - 1"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import type { SectionTypes } from '~/plugins/customSectionClasses';
import OpportunitiesItem from '~/cms/blocks/RmmTechnicalOpportunitesBlock/OpportunitiesItem.vue';

const { $customSectionClasses } = useNuxtApp();

export type TechnicalOpportunitesItem = {
  left_text: string;
  opportunities: {text: string}[]
}

type TechnicalOpportunitesBlockType = Omit<IBlockDefaultProps, 'values' | 'cfgs'> & {
  values: {
    items: TechnicalOpportunitesItem[]
  },
  cfgs: SectionTypes;
};

const props = defineProps<TechnicalOpportunitesBlockType>();
</script>

<style scoped lang="scss">
.opportunities-root {
  padding: 112px 0 40px;
  position: relative;
  background: linear-gradient(223deg, rgb(202 213 226 / 30%) 12.42%, rgb(202 213 226 / 20%) 60.95%);

}

.opportunities-items {
  display: flex;
  flex-direction: column;
  gap: 56px
}

.patternImg {
  position: absolute;
  top: 16px ;
  left: 10px;
}

.patternImg2 {
  position: absolute;
  top: 16px ;
  right: 10px;
}

.whiteSquare {
  position: absolute;
  bottom: 80px;
  left: 0;
}

@media (max-width: 1100px) {
  .patternImg, .whiteSquare, .patternImg2 {
    display: none;
  }

  .opportunities-root {
    padding: 48px 0 40px;
  }
}

@media (max-width: 640px) {
  .opportunities-items {
    gap: 26px
  }
}

</style>
